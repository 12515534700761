// frontend/src/pages/Dashboard.js

import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import {
  PlusIcon,
  UsersIcon,
  MapIcon,
  DocumentIcon,
  ArrowPathIcon,
  EyeIcon,
  EyeSlashIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline';
import Header from '../components/Header';
import { Line, Bar, Doughnut, Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  Filler,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { fetchJobs, fetchCustomers, fetchEmployees, fetchAnalytics } from '../api/dashboardApi';

// Removed import for TrialTimer
// import TrialTimer from '../components/TrialTimer';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  Filler
);

const Dashboard = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [jobCount, setJobCount] = useState(0);
  const [customerCount, setCustomerCount] = useState(0);
  const [employeeCount, setEmployeeCount] = useState(0);
  const [analyticsData, setAnalyticsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [globalTimeRange, setGlobalTimeRange] = useState('30');
  const [customDateRange, setCustomDateRange] = useState({ start: '', end: '' });
  const [showInsights, setShowInsights] = useState(true);
  const [currentChartIndex, setCurrentChartIndex] = useState(0);
  const [counts, setCounts] = useState({
    jobs: 0,
    customers: 0,
    employees: 0
  });

  useEffect(() => {
    fetchDashboardData();
    const interval = setInterval(fetchDashboardData, 600000); // Refresh every 10 minutes
    return () => clearInterval(interval);
  }, []);

  const fetchDashboardData = async () => {
    setLoading(true);
    setError(null);
    try {
      const [jobs, customers, employees] = await Promise.all([
        fetchJobs(),
        fetchCustomers(),
        fetchEmployees()
      ]);

      setCounts({
        jobs: jobs.length || 0,
        customers: customers.length || 0,
        employees: employees.length || 0
      });

    } catch (error) {
      console.error('Error fetching dashboard data:', error);
      setError('Failed to load dashboard data. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleTimeRangeChange = (e) => {
    const selectedRange = e.target.value;
    setGlobalTimeRange(selectedRange);
    if (selectedRange !== 'custom') {
      setCustomDateRange({ start: '', end: '' });
    }
  };

  const handleCustomDateChange = (e) => {
    setCustomDateRange({ ...customDateRange, [e.target.name]: e.target.value });
  };

  const applyCustomDateRange = () => {
    if (customDateRange.start && customDateRange.end) {
      fetchDashboardData('custom', customDateRange);
    }
  };

  const baseChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
        ticks: {
          color: 'rgba(255, 255, 255, 0.7)',
        }
      },
      x: {
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
        ticks: {
          color: 'rgba(255, 255, 255, 0.7)',
        }
      }
    },
    plugins: {
      legend: {
        labels: {
          color: 'rgba(255, 255, 255, 0.9)',
          font: {
            size: 12
          }
        }
      },
      title: {
        color: 'rgba(255, 255, 255, 0.9)',
        font: {
          size: 16,
          weight: 'bold'
        }
      }
    }
  };

  const charts = [
    // Screen 1: Financial Insights
    {
      title: "Revenue Overview",
      charts: [
        {
          title: "Revenue Over Time",
          component: (
            <Line
              data={{
                labels: analyticsData?.revenue?.map((item) => item._id) || [],
                datasets: [{
                  label: 'Total Revenue ($)',
                  data: analyticsData?.revenue?.map((item) => item.totalRevenue) || [],
                  fill: true,
                  backgroundColor: 'rgba(59, 130, 246, 0.2)',
                  borderColor: 'rgba(59, 130, 246, 1)',
                  tension: 0.4,
                }],
              }}
              options={{
                ...baseChartOptions,
                plugins: {
                  ...baseChartOptions.plugins,
                  title: {
                    ...baseChartOptions.plugins.title,
                    text: 'Revenue Trends'
                  }
                }
              }}
            />
          ),
        },
        {
          title: "Monthly Revenue Comparison",
          component: (
            <Bar
              data={{
                labels: analyticsData?.monthlyRevenue?.map((item) => item._id) || [],
                datasets: [{
                  label: 'Revenue',
                  data: analyticsData?.monthlyRevenue?.map((item) => item.total) || [],
                  backgroundColor: 'rgba(54, 162, 235, 0.6)',
                }],
              }}
              options={{
                ...baseChartOptions,
              }}
            />
          ),
        },
        {
          title: "Average Job Value",
          component: (
            <Line
              data={{
                labels: analyticsData?.avgJobValue?.map((item) => item._id) || [],
                datasets: [{
                  label: 'Average Value ($)',
                  data: analyticsData?.avgJobValue?.map((item) => item.average) || [],
                  borderColor: 'rgba(255, 99, 132, 1)',
                }],
              }}
              options={{
                ...baseChartOptions,
              }}
            />
          ),
        },
      ],
    },
    // Screen 2: Customer Insights
    {
      title: "Customer Analytics",
      charts: [
        {
          title: "Customer Growth",
          component: (
            <Bar
              data={{
                labels: analyticsData?.customerGrowth?.map((item) => item._id) || [],
                datasets: [{
                  label: 'New Customers',
                  data: analyticsData?.customerGrowth?.map((item) => item.newCustomers) || [],
                  backgroundColor: 'rgba(153, 102, 255, 0.6)',
                }],
              }}
              options={{
                ...baseChartOptions,
              }}
            />
          ),
        },
        {
          title: "Customer Retention",
          component: (
            <Doughnut
              data={{
                labels: ['Retained', 'Lost', 'New'],
                datasets: [{
                  data: analyticsData?.customerRetention || [0, 0, 0],
                  backgroundColor: [
                    'rgba(75, 192, 192, 0.6)',
                    'rgba(255, 99, 132, 0.6)',
                    'rgba(255, 206, 86, 0.6)',
                  ],
                }],
              }}
              options={{
                ...baseChartOptions,
              }}
            />
          ),
        },
        {
          title: "Customer Service Types",
          component: (
            <Pie
              data={{
                labels: analyticsData?.serviceTypes?.map((item) => item._id) || [],
                datasets: [{
                  data: analyticsData?.serviceTypes?.map((item) => item.count) || [],
                  backgroundColor: [
                    'rgba(54, 162, 235, 0.6)',
                    'rgba(255, 206, 86, 0.6)',
                    'rgba(75, 192, 192, 0.6)',
                  ],
                }],
              }}
              options={{
                ...baseChartOptions,
              }}
            />
          ),
        },
      ],
    },
    // Screen 3: Operational Insights
    {
      title: "Operational Analytics",
      charts: [
        {
          title: "Job Completion Rate",
          component: (
            <Doughnut
              data={{
                labels: ['Completed', 'In Progress', 'Scheduled'],
                datasets: [{
                  data: analyticsData?.jobCompletion || [0, 0, 0],
                  backgroundColor: [
                    'rgba(75, 192, 192, 0.6)',
                    'rgba(255, 206, 86, 0.6)',
                    'rgba(54, 162, 235, 0.6)',
                  ],
                }],
              }}
              options={{
                ...baseChartOptions,
              }}
            />
          ),
        },
        {
          title: "Average Service Duration",
          component: (
            <Bar
              data={{
                labels: analyticsData?.serviceDuration?.map((item) => item._id) || [],
                datasets: [{
                  label: 'Hours',
                  data: analyticsData?.serviceDuration?.map((item) => item.avgDuration) || [],
                  backgroundColor: 'rgba(153, 102, 255, 0.6)',
                }],
              }}
              options={{
                ...baseChartOptions,
              }}
            />
          ),
        },
        {
          title: "Route Efficiency",
          component: (
            <Line
              data={{
                labels: analyticsData?.routeEfficiency?.map((item) => item._id) || [],
                datasets: [{
                  label: 'Jobs Per Route',
                  data: analyticsData?.routeEfficiency?.map((item) => item.jobsPerRoute) || [],
                  borderColor: 'rgba(255, 159, 64, 1)',
                  tension: 0.4,
                }],
              }}
              options={{
                ...baseChartOptions,
              }}
            />
          ),
        },
      ],
    },
    // Screen 4: Employee Performance
    {
      title: "Employee Performance",
      charts: [
        {
          title: "Jobs Completed by Employee",
          component: (
            <Bar
              data={{
                labels: analyticsData?.employeePerformance?.map((item) => item._id) || [],
                datasets: [{
                  label: 'Completed Jobs',
                  data: analyticsData?.employeePerformance?.map((item) => item.completedJobs) || [],
                  backgroundColor: 'rgba(75, 192, 192, 0.6)',
                }],
              }}
              options={{
                ...baseChartOptions,
                indexAxis: 'y',
              }}
            />
          ),
        },
        {
          title: "Employee Efficiency Score",
          component: (
            <Line
              data={{
                labels: analyticsData?.employeeEfficiency?.map((item) => item._id) || [],
                datasets: [{
                  label: 'Efficiency Score',
                  data: analyticsData?.employeeEfficiency?.map((item) => item.score) || [],
                  borderColor: 'rgba(255, 99, 132, 1)',
                  fill: true,
                  backgroundColor: 'rgba(255, 99, 132, 0.2)',
                }],
              }}
              options={{
                ...baseChartOptions,
                scales: {
                  y: {
                    beginAtZero: true,
                    max: 100,
                  },
                },
              }}
            />
          ),
        },
        {
          title: "Service Quality Ratings",
          component: (
            <Pie
              data={{
                labels: ['5★', '4★', '3★', '2★', '1★'],
                datasets: [{
                  data: analyticsData?.serviceRatings || [0, 0, 0, 0, 0],
                  backgroundColor: [
                    'rgba(75, 192, 192, 0.6)',
                    'rgba(54, 162, 235, 0.6)',
                    'rgba(255, 206, 86, 0.6)',
                    'rgba(255, 159, 64, 0.6)',
                    'rgba(255, 99, 132, 0.6)',
                  ],
                }],
              }}
              options={{
                ...baseChartOptions,
              }}
            />
          ),
        },
      ],
    },
  ];

  const nextChart = () => {
    setCurrentChartIndex((prevIndex) => (prevIndex + 1) % charts.length);
  };

  const prevChart = () => {
    setCurrentChartIndex((prevIndex) => (prevIndex - 1 + charts.length) % charts.length);
  };

  const renderChartCarousel = () => {
    const currentScreen = charts[currentChartIndex];
    return (
      <div className="relative">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-semibold text-white">{currentScreen.title}</h2>
          <div className="flex space-x-3">
            <button
              onClick={prevChart}
              className="bg-gray-700 hover:bg-gray-600 text-white p-2 rounded-lg transition-colors duration-200"
            >
              <ChevronLeftIcon className="h-6 w-6" />
            </button>
            <button
              onClick={nextChart}
              className="bg-gray-700 hover:bg-gray-600 text-white p-2 rounded-lg transition-colors duration-200"
            >
              <ChevronRightIcon className="h-6 w-6" />
            </button>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {currentScreen.charts.map((chart, index) => (
            <div 
              key={index} 
              className="bg-gray-800 p-6 rounded-xl shadow-lg border border-gray-700"
              style={{ height: '400px' }}
            >
              <h3 className="text-lg font-medium mb-4 text-white">{chart.title}</h3>
              <div className="h-[320px]">
                {chart.component}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      <Header />

      <main className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">

        <div className="flex justify-between items-center mb-6">
          <h1 className="text-3xl font-bold">Welcome, {user?.name || 'User'}</h1>
          <div className="flex items-center">
            <select
              value={globalTimeRange}
              onChange={handleTimeRangeChange}
              className="mr-2 p-2 border rounded text-black"
            >
              <option value="7">Past 7 days</option>
              <option value="30">Past 30 days</option>
              <option value="90">Past 90 days</option>
              <option value="all">All time</option>
              <option value="custom">Custom range</option>
            </select>
            {globalTimeRange === 'custom' && (
              <>
                <input
                  type="date"
                  name="start"
                  value={customDateRange.start}
                  onChange={handleCustomDateChange}
                  className="mr-2 p-2 border rounded"
                />
                <input
                  type="date"
                  name="end"
                  value={customDateRange.end}
                  onChange={handleCustomDateChange}
                  className="mr-2 p-2 border rounded"
                />
                <button
                  onClick={applyCustomDateRange}
                  className="bg-blue-500 text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-blue-600"
                >
                  Apply
                </button>
              </>
            )}
            <button
              onClick={() => fetchDashboardData(globalTimeRange, globalTimeRange === 'custom' ? customDateRange : null)}
              className="ml-4 bg-green-500 text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-green-600 flex items-center"
            >
              <ArrowPathIcon className="h-5 w-5 mr-2" />
              Refresh Dashboard
            </button>
          </div>
        </div>

        {error && (
          <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-6" role="alert">
            <p>{error}</p>
          </div>
        )}

        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 mb-8">
          <OverviewCard 
            title="Jobs" 
            count={counts.jobs} 
            link="/manage-jobs"
            loading={loading} 
          />
          <OverviewCard 
            title="Customers" 
            count={counts.customers} 
            link="/manage-customers"
            loading={loading} 
          />
          <OverviewCard 
            title="Employees" 
            count={counts.employees} 
            link="/manage-employees"
            loading={loading} 
          />
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Quick Actions</h2>
          <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 lg:grid-cols-5">
            <QuickActionButton to="/jobs" icon={PlusIcon} text="Create New Job" />
            <QuickActionButton to="/customers" icon={PlusIcon} text="Add New Customer" />
            <QuickActionButton to="/add-employee" icon={UsersIcon} text="Add New Employee" />
            <QuickActionButton to="/generate-report" icon={DocumentIcon} text="Generate Report" />
            <QuickActionButton to="/build-routes" icon={MapIcon} text="Build Routes" />
          </div>
        </div>

        <div className="mb-12">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-semibold">Dashboard Insights</h2>
            <button 
              onClick={() => setShowInsights(!showInsights)} 
              className="text-white hover:text-blue-400 transition-colors duration-200"
            >
              {showInsights ? 
                <EyeSlashIcon className="h-6 w-6" /> : 
                <EyeIcon className="h-6 w-6" />
              }
            </button>
          </div>
          {showInsights && (
            <>
              {loading ? (
                <div className="text-center py-12">
                  <div className="animate-spin h-8 w-8 border-4 border-blue-500 border-t-transparent rounded-full mx-auto mb-4"></div>
                  <p>Loading dashboard data...</p>
                </div>
              ) : (
                renderChartCarousel()
              )}
            </>
          )}
        </div>
      </main>
    </div>
  );
};

const OverviewCard = ({ title, count, link, loading }) => (
  <div className="bg-gray-800 overflow-hidden shadow rounded-lg">
    <div className="px-4 py-5 sm:p-6">
      <h3 className="text-lg leading-6 font-medium text-white">{title}</h3>
      <div className="mt-2 text-3xl font-semibold text-white">
        {loading ? (
          <div className="animate-pulse bg-gray-700 h-8 w-16 rounded"></div>
        ) : (
          count
        )}
      </div>
      <div className="mt-4">
        <Link to={link} className="text-blue-400 hover:text-blue-300">
          View all {title.toLowerCase()}
        </Link>
      </div>
    </div>
  </div>
);

const QuickActionButton = ({ to, icon: Icon, text }) => (
  <Link
    to={to}
    className="bg-blue-500 text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-blue-600 flex items-center justify-center"
  >
    <Icon className="h-5 w-5 mr-2" />
    {text}
  </Link>
);

export default Dashboard;