import React, { useState } from 'react';
import { formatCurrency, formatDate } from '../utils/formatters';

const RouteDetailsModal = ({ isOpen, onClose, route, jobs }) => {
  const [currentJobIndex, setCurrentJobIndex] = useState(0);
  const currentJob = jobs[currentJobIndex];
  const totalRevenue = jobs.reduce((sum, job) => sum + (job.price || 0), 0);

  const nextJob = () => {
    setCurrentJobIndex((prev) => (prev + 1) % jobs.length);
  };

  const prevJob = () => {
    setCurrentJobIndex((prev) => (prev - 1 + jobs.length) % jobs.length);
  };

  if (!isOpen || !route) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-gray-800 rounded-xl p-6 max-w-4xl w-full mx-4 shadow-2xl transform transition-all">
        {/* Header */}
        <div className="flex justify-between items-center mb-8">
          <div>
            <h2 className="text-2xl font-bold text-white mb-2">Route Details</h2>
            <p className="text-gray-400">{formatDate(route.date)}</p>
          </div>
          <button 
            onClick={onClose} 
            className="text-gray-400 hover:text-white transition-colors p-2 hover:bg-gray-700 rounded-full"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        {/* Stats Cards */}
        <div className="grid grid-cols-3 gap-6 mb-8">
          <div className="bg-gray-700 rounded-xl p-4 border-l-4 border-blue-500">
            <p className="text-gray-400 text-sm mb-1">Total Revenue</p>
            <p className="text-white text-2xl font-bold">{formatCurrency(totalRevenue)}</p>
          </div>
          <div className="bg-gray-700 rounded-xl p-4 border-l-4 border-green-500">
            <p className="text-gray-400 text-sm mb-1">Total Jobs</p>
            <p className="text-white text-2xl font-bold">{jobs.length}</p>
          </div>
          <div className="bg-gray-700 rounded-xl p-4 border-l-4 border-purple-500">
            <p className="text-gray-400 text-sm mb-1">Territory</p>
            <p className="text-white text-2xl font-bold capitalize">{route.territory || 'Unassigned'}</p>
          </div>
        </div>

        {/* Job Details Section */}
        {jobs.length > 0 && (
          <div className="bg-gray-700 rounded-xl overflow-hidden">
            {/* Job Navigation */}
            <div className="bg-gray-600 p-4 flex justify-between items-center">
              <button 
                onClick={prevJob}
                className="bg-gray-700 p-2 rounded-lg hover:bg-gray-500 transition-colors"
              >
                <svg className="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
                </svg>
              </button>
              <span className="text-white font-medium">Job {currentJobIndex + 1} of {jobs.length}</span>
              <button 
                onClick={nextJob}
                className="bg-gray-700 p-2 rounded-lg hover:bg-gray-500 transition-colors"
              >
                <svg className="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                </svg>
              </button>
            </div>

            {/* Job Details */}
            <div className="p-6 space-y-4">
              <div className="grid grid-cols-2 gap-4">
                <div className="space-y-4">
                  <div>
                    <p className="text-gray-400 text-sm mb-1">Customer</p>
                    <p className="text-white font-medium">{currentJob.customer?.name}</p>
                  </div>
                  <div>
                    <p className="text-gray-400 text-sm mb-1">Address</p>
                    <p className="text-white font-medium">{currentJob.location?.address}</p>
                  </div>
                  <div>
                    <p className="text-gray-400 text-sm mb-1">Job Type</p>
                    <p className="text-white font-medium">{currentJob.service}</p>
                  </div>
                </div>
                <div className="space-y-4">
                  <div>
                    <p className="text-gray-400 text-sm mb-1">Price</p>
                    <p className="text-white font-medium">{formatCurrency(currentJob.price)}</p>
                  </div>
                  <div>
                    <p className="text-gray-400 text-sm mb-1">Duration</p>
                    <p className="text-white font-medium">{Math.round(currentJob.estimatedDuration / 60000)} mins</p>
                  </div>
                  <div>
                    <p className="text-gray-400 text-sm mb-1">Frequency</p>
                    <p className="text-white font-medium">{currentJob.recurrencePattern || 'One-time'}</p>
                  </div>
                </div>
              </div>
              
              {/* Description */}
              <div className="mt-6">
                <p className="text-gray-400 text-sm mb-2">Description</p>
                <p className="text-white bg-gray-600 p-4 rounded-lg">
                  {currentJob.description || 'No description provided'}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RouteDetailsModal; 