import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import Navbar from '../components/Navbar';
import { PDFDownloadLink } from '@react-pdf/renderer';
import QuotePDF from '../components/QuotePDF';
import { v4 as uuidv4 } from 'uuid';
import { FaFileInvoiceDollar, FaRocket, FaChartLine, FaUsers } from 'react-icons/fa';

const FeatureCard = ({ icon, title, description }) => (
  <div className="bg-gradient-to-br from-surface to-gray-800 p-6 rounded-lg text-center border border-gray-700 shadow-lg hover:shadow-xl transition duration-300">
    <div className="text-blue-400 text-3xl mb-4">{icon}</div>
    <h3 className="text-xl font-semibold mb-2 text-white">{title}</h3>
    <p className="text-blue-100">{description}</p>
  </div>
);

const QuoteBuilderPage = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [businessInfo, setBusinessInfo] = useState({
    name: '',
    address: '',
    phone: '',
    email: '',
    website: '',
  });

  const [customerInfo, setCustomerInfo] = useState({
    name: '',
    email: '',
    phone: '',
    address: {
      street: '',
      city: '',
      state: '',
      zipCode: '',
    },
  });

  const [quoteNumber, setQuoteNumber] = useState(`QUOTE-${uuidv4().slice(0, 8).toUpperCase()}`);
  const [quoteName, setQuoteName] = useState('estimate.pdf');
  const [expirationDate, setExpirationDate] = useState(() => {
    const d = new Date();
    d.setDate(d.getDate() + 30);
    return d.toISOString().slice(0,10);
  });
  const [terms, setTerms] = useState('Payment due upon completion. Valid for 30 days.');

  const [lineItems, setLineItems] = useState([
    { service: '', description: '', price: '' }
  ]);

  const addLineItem = () => {
    setLineItems([...lineItems, { service: '', description: '', price: '' }]);
  };

  const removeLineItem = (index) => {
    setLineItems(lineItems.filter((_, i) => i !== index));
  };

  const updateLineItem = (index, field, value) => {
    const updated = [...lineItems];
    updated[index][field] = value;
    setLineItems(updated);
  };

  const calculateTotal = () => {
    return lineItems.reduce((sum, item) => {
      const price = parseFloat(item.price);
      return sum + (isNaN(price) ? 0 : price);
    }, 0);
  };

  const renderCallToAction = () => {
    return (
      <div className="mt-12 mb-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-blue-100 mb-4">Ready to Grow Your Business?</h2>
          <p className="text-xl text-blue-200 mb-8">
            Create an account to unlock premium features and grow your lawn care business
          </p>
          <div className="grid md:grid-cols-3 gap-8 mb-12">
            <FeatureCard
              icon={<FaFileInvoiceDollar />}
              title="Save Quotes"
              description="Store and manage all your quotes in one place"
            />
            <FeatureCard
              icon={<FaChartLine />}
              title="Business Analytics"
              description="Track your growth and analyze performance"
            />
            <FeatureCard
              icon={<FaUsers />}
              title="Customer Management"
              description="Manage your customer relationships efficiently"
            />
          </div>
          <button
            onClick={() => navigate('/register')}
            className="px-8 py-3 rounded-md bg-gradient-to-r from-blue-500 to-blue-600 text-white font-medium hover:from-blue-600 hover:to-blue-700 transform hover:scale-105 transition duration-200 shadow-lg hover:shadow-blue-500/50"
          >
            Create Free Account
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-gradient-to-b from-gray-900 to-background text-text min-h-screen">
      <Navbar />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center mb-12">
          <div className="relative">
            <div className="absolute inset-0 blur-[100px] bg-gradient-to-r from-blue-500/30 to-purple-500/30 transform rotate-12"></div>
            <h1 className="relative text-4xl font-extrabold sm:text-5xl md:text-6xl mb-4 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
              Professional Quote Builder
            </h1>
          </div>
          <p className="mt-3 max-w-2xl mx-auto text-xl text-blue-100">
            Create beautiful, professional quotes for your lawn care services
          </p>
        </div>

        <div className="bg-gradient-to-br from-surface to-gray-800 rounded-lg border border-gray-700 shadow-xl p-8">
          <section className="mb-8">
            <h2 className="text-2xl font-bold text-blue-100 mb-4">Business Information</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <label className="block">
                <span className="text-blue-100">Business Name:</span>
                <input
                  type="text"
                  value={businessInfo.name}
                  onChange={(e) => setBusinessInfo({...businessInfo, name: e.target.value})}
                  className="mt-1 w-full bg-gray-700/50 text-white p-2 rounded border border-gray-600 focus:border-blue-400 focus:ring focus:ring-blue-400/50"
                />
              </label>
              <label>
                Phone:
                <input
                  type="text"
                  value={businessInfo.phone}
                  onChange={(e) => setBusinessInfo({...businessInfo, phone: e.target.value})}
                  className="mt-1 w-full bg-gray-700/50 text-white p-2 rounded border border-gray-600 focus:border-blue-400 focus:ring focus:ring-blue-400/50"
                />
              </label>
              <label>
                Email:
                <input
                  type="text"
                  value={businessInfo.email}
                  onChange={(e) => setBusinessInfo({...businessInfo, email: e.target.value})}
                  className="mt-1 w-full bg-gray-700/50 text-white p-2 rounded border border-gray-600 focus:border-blue-400 focus:ring focus:ring-blue-400/50"
                />
              </label>
              <label className="md:col-span-2">
                Address:
                <input
                  type="text"
                  value={businessInfo.address}
                  onChange={(e) => setBusinessInfo({...businessInfo, address: e.target.value})}
                  className="mt-1 w-full bg-gray-700/50 text-white p-2 rounded border border-gray-600 focus:border-blue-400 focus:ring focus:ring-blue-400/50"
                />
              </label>
              <label>
                Website:
                <input
                  type="text"
                  value={businessInfo.website}
                  onChange={(e) => setBusinessInfo({...businessInfo, website: e.target.value})}
                  className="mt-1 w-full bg-gray-700/50 text-white p-2 rounded border border-gray-600 focus:border-blue-400 focus:ring focus:ring-blue-400/50"
                />
              </label>
            </div>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold text-blue-100 mb-4">Customer Information</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <label>
                Name:
                <input
                  type="text"
                  value={customerInfo.name}
                  onChange={(e) => setCustomerInfo({...customerInfo, name: e.target.value})}
                  className="mt-1 w-full bg-gray-700 text-white p-2 rounded"
                />
              </label>
              <label>
                Email:
                <input
                  type="email"
                  value={customerInfo.email}
                  onChange={(e) => setCustomerInfo({...customerInfo, email: e.target.value})}
                  className="mt-1 w-full bg-gray-700 text-white p-2 rounded"
                />
              </label>
              <label>
                Phone:
                <input
                  type="text"
                  value={customerInfo.phone}
                  onChange={(e) => setCustomerInfo({...customerInfo, phone: e.target.value})}
                  className="mt-1 w-full bg-gray-700 text-white p-2 rounded"
                />
              </label>
              <label>
                Street:
                <input
                  type="text"
                  value={customerInfo.address.street}
                  onChange={(e) => setCustomerInfo({...customerInfo, address: {...customerInfo.address, street: e.target.value}})}
                  className="mt-1 w-full bg-gray-700 text-white p-2 rounded"
                />
              </label>
              <label>
                City:
                <input
                  type="text"
                  value={customerInfo.address.city}
                  onChange={(e) => setCustomerInfo({...customerInfo, address: {...customerInfo.address, city: e.target.value}})}
                  className="mt-1 w-full bg-gray-700 text-white p-2 rounded"
                />
              </label>
              <label>
                State:
                <input
                  type="text"
                  value={customerInfo.address.state}
                  onChange={(e) => setCustomerInfo({...customerInfo, address: {...customerInfo.address, state: e.target.value}})}
                  className="mt-1 w-full bg-gray-700 text-white p-2 rounded"
                />
              </label>
              <label>
                Zip Code:
                <input
                  type="text"
                  value={customerInfo.address.zipCode}
                  onChange={(e) => setCustomerInfo({...customerInfo, address: {...customerInfo.address, zipCode: e.target.value}})}
                  className="mt-1 w-full bg-gray-700 text-white p-2 rounded"
                />
              </label>
            </div>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold text-blue-100 mb-4">Quote Details</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <label>
                Quote Number:
                <input
                  type="text"
                  value={quoteNumber}
                  onChange={(e) => setQuoteNumber(e.target.value)}
                  className="mt-1 w-full bg-gray-700/50 text-white p-2 rounded border border-gray-600 focus:border-blue-400 focus:ring focus:ring-blue-400/50"
                />
              </label>
              <label>
                Valid Until:
                <input
                  type="date"
                  value={expirationDate}
                  onChange={(e) => setExpirationDate(e.target.value)}
                  className="mt-1 w-full bg-gray-700/50 text-white p-2 rounded border border-gray-600 focus:border-blue-400 focus:ring focus:ring-blue-400/50"
                />
              </label>
            </div>

            <div className="mt-4">
              <h3 className="text-xl font-semibold mb-2">Line Items</h3>
              {lineItems.map((item, index) => (
                <div key={index} className="bg-gray-800 p-4 rounded mb-2">
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <label>
                      Service:
                      <input
                        type="text"
                        value={item.service}
                        onChange={(e) => updateLineItem(index, 'service', e.target.value)}
                        className="mt-1 w-full bg-gray-700/50 text-white p-2 rounded border border-gray-600 focus:border-blue-400 focus:ring focus:ring-blue-400/50"
                      />
                    </label>
                    <label>
                      Description:
                      <input
                        type="text"
                        value={item.description}
                        onChange={(e) => updateLineItem(index, 'description', e.target.value)}
                        className="mt-1 w-full bg-gray-700/50 text-white p-2 rounded border border-gray-600 focus:border-blue-400 focus:ring focus:ring-blue-400/50"
                      />
                    </label>
                    <label>
                      Price:
                      <input
                        type="number"
                        step="0.01"
                        value={item.price}
                        onChange={(e) => updateLineItem(index, 'price', e.target.value)}
                        className="mt-1 w-full bg-gray-700/50 text-white p-2 rounded border border-gray-600 focus:border-blue-400 focus:ring focus:ring-blue-400/50"
                      />
                    </label>
                  </div>
                  {index > 0 && (
                    <button
                      onClick={() => removeLineItem(index)}
                      className="mt-2 text-red-400 hover:text-red-300"
                    >
                      Remove Item
                    </button>
                  )}
                </div>
              ))}
              <button
                onClick={addLineItem}
                className="bg-gradient-to-r from-blue-500 to-blue-600 text-white px-4 py-2 rounded hover:from-blue-600 hover:to-blue-700 transform hover:scale-105 transition duration-200"
              >
                Add Line Item
              </button>
            </div>

            <div className="mt-4">
              <label className="block">
                Terms & Conditions:
                <textarea
                  value={terms}
                  onChange={(e) => setTerms(e.target.value)}
                  className="mt-1 w-full bg-gray-700/50 text-white p-2 rounded border border-gray-600 focus:border-blue-400 focus:ring focus:ring-blue-400/50 h-24"
                />
              </label>
            </div>
          </section>

          <div className="bg-gray-800/50 p-6 rounded-lg border border-gray-700 mt-8">
            <h3 className="text-xl font-semibold mb-4 text-blue-100">Preview & Export</h3>
            <p className="text-blue-200 mb-4">Total: ${calculateTotal().toFixed(2)}</p>
            <PDFDownloadLink
              document={
                <QuotePDF
                  quote={{
                    _id: quoteNumber,
                    lineItems: lineItems.map(item => ({
                      ...item,
                      price: parseFloat(item.price) || 0
                    })),
                    totalPrice: calculateTotal(),
                    expirationDate,
                    terms,
                  }}
                  customerInfo={customerInfo}
                  businessInfo={businessInfo}
                />
              }
              fileName={quoteName}
              className="inline-block px-6 py-3 rounded-md bg-gradient-to-r from-blue-500 to-blue-600 text-white font-medium hover:from-blue-600 hover:to-blue-700 transform hover:scale-105 transition duration-200 shadow-lg hover:shadow-blue-500/50"
            >
              {({ blob, url, loading, error }) =>
                loading ? 'Generating PDF...' : 'Download PDF'
              }
            </PDFDownloadLink>
          </div>
        </div>

        {renderCallToAction()}
      </div>
    </div>
  );
};

export default QuoteBuilderPage; 