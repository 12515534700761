import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const ProtectedRoute = ({ requireSubscription = false }) => {
  const { user } = useAuth();

  if (!user) {
    // Not logged in, redirect to login page
    return <Navigate to="/signin" replace />;
  }

  if (requireSubscription && (!user.subscriptionActive || !user.subscriptionTier)) {
    // No active subscription when required, redirect to pricing
    return <Navigate to="/pricing" replace state={{ 
      message: 'Please select a subscription plan to access this feature'
    }} />;
  }

  // Authorized, render children
  return <Outlet />;
};

export default ProtectedRoute;
