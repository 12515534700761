// src/components/Alert.js
import React from 'react';

const Alert = ({ type = 'info', message, errors }) => {
  const bgColor = type === 'error' ? 'bg-red-100' : type === 'success' ? 'bg-green-100' : 'bg-blue-100';
  const textColor = type === 'error' ? 'text-red-700' : type === 'success' ? 'text-green-700' : 'text-blue-700';
  const borderColor = type === 'error' ? 'border-red-400' : type === 'success' ? 'border-green-400' : 'border-blue-400';

  return (
    <div className={`${bgColor} border-l-4 ${borderColor} p-4 mb-4 rounded`}>
      <p className={`${textColor} font-medium`}>{message}</p>
      {errors && errors.length > 0 && (
        <ul className={`${textColor} list-disc list-inside mt-2`}>
          {errors.map((error, index) => (
            <li key={index} className="text-sm">{error}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Alert;
