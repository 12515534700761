import React, { useState, useEffect, useMemo } from 'react';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import axiosInstance from '../utils/axiosInstance';
import Header from '../components/Header';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../styles/calendar.css';
import RecurringJobModal from '../components/RecurringJobModal';
import { Modal } from 'react-bootstrap';
import { Select } from 'antd';
import { toast } from 'react-hot-toast';
import RouteDetailsModal from '../components/RouteDetailsModal';

const localizer = momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(BigCalendar);

const Calendar = () => {
  const [rawEvents, setRawEvents] = useState([]);
  const [unscheduledJobs, setUnscheduledJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [selectedRouteJobs, setSelectedRouteJobs] = useState([]);
  const [showRouteDetails, setShowRouteDetails] = useState(false);
  const [showRouteModal, setShowRouteModal] = useState(false);
  const [modalState, setModalState] = useState({ 
    isOpen: false, 
    jobData: null, 
    dropDate: null, 
    existingRoute: null 
  });
  const [jobData, setJobData] = useState(null);
  const [dropDate, setDropDate] = useState(null);
  const [existingRoute, setExistingRoute] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [currentView, setCurrentView] = useState('month');

  useEffect(() => {
    fetchInitialData();
  }, []);

  const fetchInitialData = async () => {
    try {
      setLoading(true);
      const start = moment().startOf('month').toISOString();
      const end = moment().endOf('month').toISOString();

      const [eventsResponse, jobsResponse] = await Promise.all([
        axiosInstance.get(`/calendar/events?start=${start}&end=${end}`),
        axiosInstance.get('/calendar/unscheduled-jobs')
      ]);

      setRawEvents(eventsResponse.data);
      setUnscheduledJobs(jobsResponse.data);
    } catch (err) {
      setError('Failed to load calendar data');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const formatEvent = (event, allEvents, view) => {
    console.log('Formatting event:', event, 'View:', view);

    // For week and day views - show individual jobs
    if (view === 'week' || view === 'day') {
      return {
        id: `job-${event._id}`,
        title: `${event.job?.customer?.name || 'No Customer'} - ${event.job?.service || 'No Service'}`,
        start: new Date(event.start),
        end: new Date(event.end),
        resource: {
          job: event.job,
          route: event.route,
          isRecurring: event.isRecurring
        },
        className: `calendar-event ${event.isRecurring ? 'recurring-event' : ''} ${event.route ? 'route-job' : ''}`,
        backgroundColor: event.isRecurring ? '#4ade80' : '#2563eb'
      };
    }

    // For month view - group by route
    if (event.route) {
      const routeEvents = allEvents.filter(e => 
        e.route && 
        e.route._id === event.route._id &&
        new Date(e.start).toDateString() === new Date(event.start).toDateString()
      );
      
      if (event === routeEvents[0]) {
        return {
          id: `route-${event.route._id}-${new Date(event.start).toDateString()}`,
          title: `Route (${routeEvents.length} jobs)`,
          start: new Date(event.start),
          end: new Date(event.end),
          resource: {
            route: event.route,
            jobs: routeEvents.map(e => e.job)
          },
          className: 'route-event',
          backgroundColor: '#2563eb'
        };
      }
      return null;
    }

    // For individual jobs not in a route
    return {
      id: `job-${event._id}`,
      title: event.job?.service || 'Unnamed Job',
      start: new Date(event.start),
      end: new Date(event.end),
      resource: {
        job: event.job,
        isRecurring: event.isRecurring
      },
      className: 'calendar-event',
      backgroundColor: event.isRecurring ? '#4ade80' : '#2563eb'
    };
  };

  const formattedEvents = useMemo(() => {
    return rawEvents.map(event => formatEvent(event, rawEvents, currentView))
      .filter(Boolean);
  }, [rawEvents, currentView]);

  const handleRecurringJobDrop = async (jobData, existingRoute, start) => {
    try {
      // Validate the date first
      const startDate = new Date(start);
      if (isNaN(startDate.getTime())) {
        toast.error('Invalid drop date');
        return;
      }

      // Calculate end date using estimated duration or default to 1 hour
      const duration = jobData.estimatedDuration || 3600000; // 1 hour in milliseconds
      const endDate = new Date(startDate.getTime() + duration);

      // Normalize the frequency
      const normalizedFrequency = jobData.recurrencePattern.toLowerCase().replace('-', '');
      
      console.log('Creating calendar event with dates:', {
        start: startDate.toISOString(),
        end: endDate.toISOString()
      });

      const response = await axiosInstance.post('/routes/add-job', {
        routeId: existingRoute._id,
        jobId: jobData._id,
        createSeries: true,
        frequency: normalizedFrequency,
        futureOnly: true,
        start: startDate.toISOString(),
        end: endDate.toISOString()
      });

      await fetchInitialData();
      toast.success('Job scheduled successfully');
      
    } catch (error) {
      console.error('Error in handleRecurringJobDrop:', error);
      toast.error(error.response?.data?.message || 'Failed to schedule job');
    }
  };

  const handleJobDrop = async (jobData, existingRoute, start) => {
    try {
      // Validate the date first
      const startDate = new Date(start);
      if (isNaN(startDate.getTime())) {
        toast.error('Invalid drop date');
        return;
      }

      // If job is recurring and dropped on existing route
      if (jobData.isRecurring && existingRoute) {
        await handleRecurringJobDrop(jobData, existingRoute, start);
        return;
      }

      // For non-recurring jobs or new routes
      const response = await axiosInstance.post('/calendar/events', {
        jobId: jobData._id,
        date: startDate.toISOString(),
        routeId: existingRoute?._id,
        createSeries: false
      });

      await fetchInitialData();
      toast.success('Job scheduled successfully');

    } catch (error) {
      console.error('Error in handleJobDrop:', error);
      toast.error(error.response?.data?.message || 'Failed to schedule job');
    }
  };

  const handleDragStart = async (e, job) => {
    console.log('Drag started:', { job });
    try {
      // Store the dragged job ID in the backend
      await axiosInstance.post('/calendar/drag-start', { jobId: job._id });
      
      const eventData = {
        title: job.service,
        resource: { job }
      };
      console.log('Setting drag data:', eventData);
      e.dataTransfer.setData('event', JSON.stringify(eventData));
      e.dataTransfer.setData('text/plain', ''); // Required for Firefox
    } catch (err) {
      console.error('Error in handleDragStart:', err);
    }
  };

  const handleDrop = async (event) => {
    console.log('Drop event received:', event);
    
    try {
      // Get the dragged job
      const draggedJobResponse = await axiosInstance.get('/calendar/last-dragged');
      console.log('Dragged job response:', draggedJobResponse.data);
      const job = draggedJobResponse.data.job;
      setJobData(job);

      // Set drop date
      const dropDate = event.start;
      setDropDate(dropDate);

      // Check for existing routes
      const date = new Date(dropDate);
      const formattedDate = date.toISOString().split('T')[0];
      const routeResponse = await axiosInstance.get(`/routes/by-date/${formattedDate}`);

      // For one-time jobs
      if (!job.isRecurring) {
        if (routeResponse.data.routes?.length > 0) {
          // If routes exist, show route selection modal
          setModalState({
            isOpen: true,
            routes: routeResponse.data.routes,
            jobData: job,
            dropDate: dropDate,
            requireRouteSelection: true
          });
        } else {
          // If no routes, silently create route series and add job
          const response = await axiosInstance.post('/calendar/events', {
            jobId: job._id,
            date: dropDate.toISOString(),
            createSeries: true,
            start: dropDate.toISOString(),
            end: new Date(dropDate.getTime() + (job.estimatedDuration || 3600000)).toISOString()
          });
          
          await fetchInitialData();
          toast.success('Job scheduled successfully');
        }
        return;
      }

      // For recurring jobs, show the recurring options modal
      setExistingRoute(routeResponse.data);
      setShowModal(true);

    } catch (error) {
      console.error('Error handling drop:', error);
      toast.error('Failed to process drop');
    }
  };

  const handleModalSelect = async (choice, routeId) => {
    try {
      if (!jobData || !dropDate) {
        toast.error('Missing job data or drop date');
        return;
      }

      const startDate = new Date(dropDate);
      const duration = jobData.estimatedDuration || 3600000;
      const endDate = new Date(startDate.getTime() + duration);

      if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
        toast.error('Invalid drop date');
        return;
      }

      let response;
      
      // Handle route selection for one-time jobs
      if (choice === 'selectRoute' && routeId) {
        response = await axiosInstance.post('/calendar/events', {
          jobId: jobData._id,
          date: startDate.toISOString(),
          routeId: routeId,
          createSeries: false,
          start: startDate.toISOString(),
          end: endDate.toISOString()
        });
      } else if (jobData.isRecurring) {
        // Handle recurring job options
        switch (choice) {
          case 'single':
            response = await axiosInstance.post('/calendar/events', {
              jobId: jobData._id,
              date: startDate.toISOString(),
              createSeries: false,
              start: startDate.toISOString(),
              end: endDate.toISOString()
            });
            break;

          case 'newSeries':
            if (!jobData.recurrencePattern) {
              toast.error('Job is missing recurrence pattern');
              return;
            }
            response = await axiosInstance.post('/calendar/events', {
              jobId: jobData._id,
              date: startDate.toISOString(),
              createSeries: true,
              frequency: jobData.recurrencePattern.toLowerCase().replace('-', ''),
              start: startDate.toISOString(),
              end: endDate.toISOString()
            });
            break;

          case 'attachFuture':
          case 'attachAll':
            if (!existingRoute?.route?._id) {
              toast.error('No existing route found');
              return;
            }
            
            response = await axiosInstance.post('/routes/add-job', {
              routeId: existingRoute.route._id,
              jobId: jobData._id,
              createSeries: true,
              frequency: jobData.recurrencePattern?.toLowerCase().replace('-', '') || 'weekly',
              futureOnly: choice === 'attachFuture',
              start: startDate.toISOString(),
              end: endDate.toISOString()
            });
            break;

          default:
            console.error('Unknown modal choice:', choice);
            return;
        }
      }

      await fetchInitialData();
      toast.success('Job scheduled successfully');
      setModalState({ isOpen: false });
      setShowModal(false);
      
    } catch (error) {
      console.error('Error in handleModalSelect:', error);
      toast.error(error.response?.data?.message || 'Failed to schedule job');
    }
  };

  const handleRouteSelect = (routeId) => {
    setModalState(prev => ({
      ...prev,
      selectedRouteId: routeId
    }));
  };

  const onDropFromOutside = (dropEvent) => {
    console.log('onDropFromOutside called:', dropEvent);
    if (!dropEvent || !dropEvent.start) {
      console.error('Invalid drop event:', dropEvent);
      return;
    }
    handleDrop(dropEvent);
  };

  const handleEventClick = async (event) => {
    try {
      console.log('Event clicked:', event);
      const routeId = event.id.split('-')[1];
      console.log('Fetching route:', routeId);
      
      const routeResponse = await axiosInstance.get(`/routes/${routeId}`);
      console.log('Route response:', routeResponse.data);
      
      // Since jobs are already populated, we don't need to fetch them again
      const jobs = routeResponse.data.jobs;
      console.log('Jobs loaded:', jobs);
      
      setSelectedRoute(routeResponse.data);
      setSelectedRouteJobs(jobs);
      setShowRouteDetails(true);
    } catch (error) {
      console.error('Error fetching route details:', error);
      toast.error('Failed to load route details');
    }
  };

  const handleNonRecurringDrop = async (job, dropDate, existingRoute) => {
    try {
      if (existingRoute) {
        // Add to existing route
        await axiosInstance.post('/routes/add-job', {
          routeId: existingRoute._id,
          jobId: job._id,
          date: dropDate,
          createSeries: false
        });
      } else {
        // Create new route
        await axiosInstance.post('/routes', {
          date: dropDate,
          jobId: job._id,
          createSeries: false
        });
      }
      
      // Refresh calendar data
      await fetchInitialData();
    } catch (err) {
      console.error('Error handling non-recurring drop:', err);
      setError('Failed to schedule job');
    }
  };

  const checkForExistingRoute = async (date) => {
    try {
      const formattedDate = moment(date).format('YYYY-MM-DD');
      const response = await axiosInstance.get(`/routes/by-date/${formattedDate}`);
      return response.data.route || null;
    } catch (err) {
      console.error('Error checking for existing route:', err);
      return null;
    }
  };

  if (loading) return <div>Loading calendar...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="min-h-screen bg-background">
      <Header />
      <div className="flex h-[calc(100vh-64px)]">
        {/* Job Pool Sidebar */}
        <div className="w-1/4 p-4 bg-gray-100 overflow-y-auto">
          <h2 className="text-xl font-bold mb-4">Unscheduled Jobs</h2>
          <div className="space-y-2">
            {unscheduledJobs.map(job => (
              <div
                key={job._id}
                className="p-3 bg-white rounded shadow cursor-move"
                draggable
                onDragStart={(e) => handleDragStart(e, job)}
              >
                <div className="font-medium">{job.service}</div>
                <div className="text-sm text-gray-600">
                  {job.customer?.name || 'No customer assigned'}
                </div>
                {job.isRecurring && (
                  <span className="text-xs bg-green-100 text-green-800 px-2 py-1 rounded">
                    {job.recurrencePattern}
                  </span>
                )}
              </div>
            ))}
          </div>
        </div>

        {/* Calendar */}
        <div className="flex-1 p-4">
          <DragAndDropCalendar
            localizer={localizer}
            events={formattedEvents}
            onEventDrop={handleJobDrop}
            onDropFromOutside={onDropFromOutside}
            draggableAccessor={() => true}
            resizable={false}
            style={{ height: 'calc(100vh - 100px)' }}
            onSelectEvent={handleEventClick}
            droppable={true}
            onView={setCurrentView}
            view={currentView}
          />
          <RecurringJobModal
            isOpen={showModal}
            onClose={() => setShowModal(false)}
            onSelect={handleModalSelect}
            existingRoute={existingRoute}
          />
          <RouteDetailsModal
            isOpen={showRouteDetails}
            onClose={() => setShowRouteDetails(false)}
            route={selectedRoute}
            jobs={selectedRouteJobs}
          />
        </div>
      </div>
    </div>
  );
};

export default Calendar;