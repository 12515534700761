import React, { useState, useEffect } from 'react';
import axiosInstance from '../utils/axiosInstance'; // Use axiosInstance
import { PlusIcon, XMarkIcon, ExclamationCircleIcon, CheckCircleIcon, DocumentPlusIcon } from '@heroicons/react/24/outline';
import Header from '../components/Header';

const Jobs = () => {
  const [job, setJob] = useState({
    service: '',
    description: '',
    customer: '',
    price: '',
    status: 'Pending',
    isRecurring: false,
    recurrencePattern: 'one-time',
    estimatedDuration: '',
    location: {
      address: '',
      coordinates: []
    },
    cost: null,
    notes: '',
    scheduledDay: null
  });
  const [jobs, setJobs] = useState({ oneTime: [], recurring: [] });
  const [customers, setCustomers] = useState([]);
  const [services, setServices] = useState([]);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    fetchCustomers();
    fetchJobs();
    fetchServices();
  }, []);

  const fetchCustomers = async () => {
    try {
      const response = await axiosInstance.get('/customers');
      const sanitizedCustomers = response.data.map(customer => ({
        ...customer,
        address: customer.address || {},
        phone: customer.phone || 'No phone',
        email: customer.email || 'No email',
        notes: customer.notes || ''
      }));
      setCustomers(sanitizedCustomers);
    } catch (error) {
      console.error('Error fetching customers:', error);
      setError('Failed to fetch customers. Please try again.');
    }
  };

  const fetchJobs = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get('/jobs');

      // Sort jobs by createdAt date and take the last 3
      const oneTimeJobs = response.data
        .filter(job => !job.isRecurring)
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .slice(0, 3);
      const recurringJobs = response.data
        .filter(job => job.isRecurring)
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .slice(0, 3);

      setJobs({ oneTime: oneTimeJobs, recurring: recurringJobs });
    } catch (error) {
      console.error('Error fetching jobs:', error);
      setError('Failed to fetch jobs. Please try again.');
    }
    setLoading(false);
  };

const fetchServices = async () => {
  try {
    console.log('Fetching services...');
    const response = await axiosInstance.get('/services');
    console.log('Services received:', response.data);
    setServices(response.data);
  } catch (error) {
    console.error('Error fetching services:', error);
    // More detailed error logging
    if (error.response) {
      console.error('Error response:', {
        status: error.response.status,
        data: error.response.data,
        headers: error.response.headers
      });
    }
    setError('Failed to fetch services. Please try again.');
  }
};

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'service') {
      setJob(prevJob => ({
        ...prevJob,
        service: value
      }));
    } else if (name === 'customer') {
      const selectedCustomer = customers.find(customer => customer._id === value);
      if (selectedCustomer) {
        setJob(prevJob => ({
          ...prevJob,
          customer: value,
          location: {
            address: selectedCustomer.address ? 
              `${selectedCustomer.address.street || ''}, ${selectedCustomer.address.city || ''}, ${selectedCustomer.address.state || ''} ${selectedCustomer.address.zipCode || ''}`.trim() : 
              'Address not available',
            coordinates: selectedCustomer.address?.coordinates || []
          }
        }));
      }
    } else if (name === 'isRecurring') {
      setJob(prevJob => ({
        ...prevJob,
        isRecurring: value === 'true',
        recurrencePattern: value === 'true' ? 'weekly' : 'one-time'
      }));
    } else if (name === 'scheduledDay') {
      setJob(prevJob => ({
        ...prevJob,
        scheduledDay: value === '' ? null : value  // Set to null if empty string
      }));
    } else if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setJob(prevJob => ({
        ...prevJob,
        [parent]: {
          ...prevJob[parent],
          [child]: value
        }
      }));
    } else {
      setJob(prevJob => ({ ...prevJob, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    try {
      // Normalize the recurrence pattern
      const normalizedJob = {
        ...job,
        recurrencePattern: job.isRecurring 
          ? job.recurrencePattern.toLowerCase().replace('-', '') 
          : 'one-time'
      };

      console.log('Submitting job with pattern:', normalizedJob.recurrencePattern);
      
      const response = await axiosInstance.post('/jobs', normalizedJob);
      setSuccess('Job created successfully!');
      setShowForm(false);
      fetchJobs();
    } catch (error) {
      console.error('Error creating job:', error.response?.data || error);
      setError(error.response?.data?.message || 'Failed to create job');
    }
    setLoading(false);
  };

  const renderJobTable = (jobList, title) => (
    <div className="mb-8">
      <h2 className="text-xl font-semibold mb-4">{title}</h2>
      <div className="overflow-x-auto mt-4">
        <table className="min-w-full divide-y divide-gray-700">
          <thead className="bg-gray-800">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Job</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Customer</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Price</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Status</th>
              {title === "Recurring Jobs" && (
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Recurrence</th>
              )}
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Scheduled Day</th>
            </tr>
          </thead>
          <tbody className="bg-gray-900 divide-y divide-gray-700">
            {jobList.map((job) => (
              <tr key={job._id}>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-white">{job.service}</div>
                  <div className="text-sm text-gray-400">{job.description}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-white">{job.customer?.name || 'No customer assigned'}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-white">${job.price || 0}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full 
                    ${job.status === 'Completed' ? 'bg-green-100 text-green-800' : 
                      job.status === 'In Progress' ? 'bg-yellow-100 text-yellow-800' : 
                      job.status === 'Canceled' ? 'bg-red-100 text-red-800' : 
                      job.status === 'Scheduled' ? 'bg-blue-100 text-blue-800' :
                      'bg-gray-100 text-gray-800'}`}>
                    {job.status}
                  </span>
                </td>
                {title === "Recurring Jobs" && (
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-white">{job.recurrencePattern}</div>
                  </td>
                )}
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-white">{job.scheduledDay || 'Not Scheduled'}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-white">
                    {job.location?.address || 'Address not available'}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );  

return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900">
      <Header />
      <main className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
        {/* Header Section */}
        <div className="flex justify-between items-center mb-8">
          <div>
            <h1 className="text-3xl font-bold text-white">Jobs Dashboard</h1>
            <p className="text-gray-400 mt-1">Manage and create new jobs</p>
          </div>
          <button
            onClick={() => setShowForm(!showForm)}
            className="bg-blue-600 hover:bg-blue-700 text-white px-6 py-3 rounded-lg shadow-lg transition-all duration-200 flex items-center space-x-2 transform hover:scale-105"
          >
            {showForm ? (
              <XMarkIcon className="h-5 w-5" />
            ) : (
              <PlusIcon className="h-5 w-5" />
            )}
            <span>{showForm ? 'Hide Form' : 'New Job'}</span>
          </button>
        </div>

        {/* Alerts */}
        {error && (
          <div className="mb-6 bg-red-500 bg-opacity-10 border border-red-500 text-red-500 px-4 py-3 rounded-lg flex items-center">
            <ExclamationCircleIcon className="h-5 w-5 mr-2" />
            {error}
          </div>
        )}
        {success && (
          <div className="mb-6 bg-green-500 bg-opacity-10 border border-green-500 text-green-500 px-4 py-3 rounded-lg flex items-center">
            <CheckCircleIcon className="h-5 w-5 mr-2" />
            {success}
          </div>
        )}

        {/* Job Creation Form */}
        {showForm && (
          <div className="bg-gray-800 rounded-xl shadow-xl p-6 mb-8 border border-gray-700">
            <h2 className="text-2xl font-semibold text-white mb-6 flex items-center">
              <DocumentPlusIcon className="h-6 w-6 mr-2 text-blue-500" />
              Create New Job
            </h2>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Service Selection */}
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-300">
                    Service Type
                  </label>
                  <select
                    name="service"
                    value={job.service}
                    onChange={handleChange}
                    className="w-full bg-gray-700 border border-gray-600 text-white rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    required
                  >
                    <option value="">Select a service</option>
                    {services.map((service) => (
                      <option key={service._id} value={service.name}>
                        {service.name}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Customer Selection */}
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-300">
                    Customer
                  </label>
                  <select
                    name="customer"
                    value={job.customer}
                    onChange={handleChange}
                    className="w-full bg-gray-700 border border-gray-600 text-white rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    required
                  >
                    <option value="">Select a customer</option>
                    {customers.map((customer) => (
                      <option key={customer._id} value={customer._id}>
                        {customer.name}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Price Input */}
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-300">
                    Price ($)
                  </label>
                  <input
                    type="number"
                    name="price"
                    value={job.price}
                    onChange={handleChange}
                    className="w-full bg-gray-700 border border-gray-600 text-white rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    required
                  />
                </div>

                {/* Estimated Duration */}
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-300">
                    Estimated Duration (minutes)
                  </label>
                  <input
                    type="number"
                    name="estimatedDuration"
                    value={job.estimatedDuration}
                    onChange={handleChange}
                    className="w-full bg-gray-700 border border-gray-600 text-white rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    required
                  />
                </div>
              </div>

              {/* Job Type and Recurrence */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-300">
                    Job Type
                  </label>
                  <div className="flex space-x-4">
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        name="isRecurring"
                        value="false"
                        checked={!job.isRecurring}
                        onChange={handleChange}
                        className="form-radio text-blue-600"
                      />
                      <span className="ml-2 text-gray-300">One-time</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        name="isRecurring"
                        value="true"
                        checked={job.isRecurring}
                        onChange={handleChange}
                        className="form-radio text-blue-600"
                      />
                      <span className="ml-2 text-gray-300">Recurring</span>
                    </label>
                  </div>
                </div>

                {job.isRecurring && (
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-300">
                      Recurrence Pattern
                    </label>
                    <select
                      name="recurrencePattern"
                      value={job.recurrencePattern}
                      onChange={handleChange}
                      className="w-full bg-gray-700 border border-gray-600 text-white rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      required
                    >
                      <option value="">Select frequency</option>
                      <option value="weekly">Weekly</option>
                      <option value="biweekly">Bi-weekly</option>
                      <option value="monthly">Monthly</option>
                    </select>
                  </div>
                )}
              </div>

              {/* Submit Button */}
              <div className="flex justify-end mt-6">
                <button
                  type="submit"
                  disabled={loading}
                  className="bg-blue-600 hover:bg-blue-700 text-white px-6 py-3 rounded-lg shadow-lg transition-all duration-200 flex items-center space-x-2 disabled:opacity-50"
                >
                  {loading ? (
                    <>
                      <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white"></div>
                      <span>Creating...</span>
                    </>
                  ) : (
                    <>
                      <PlusIcon className="h-5 w-5" />
                      <span>Create Job</span>
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>
        )}

        {/* Updated Job Tables */}
        {loading ? (
          <div className="flex flex-col items-center justify-center py-12">
            <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-blue-500"></div>
            <p className="mt-4 text-gray-400">Loading jobs...</p>
          </div>
        ) : (
          <div className="space-y-8">
            {/* One-Time Jobs Table */}
            <div className="bg-gray-800/50 rounded-xl overflow-hidden border border-gray-700">
              <h2 className="text-xl font-semibold text-white p-6">One-Time Jobs</h2>
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-700">
                  <thead className="bg-gray-900/50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Job</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Customer</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Price</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Status</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Scheduled Day</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Location</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-700">
                    {jobs.oneTime.map((job) => (
                      <tr key={job._id} className="hover:bg-gray-700/50 transition-colors">
                        <td className="px-6 py-4">
                          <div className="text-sm font-medium text-white">{job.service}</div>
                          <div className="text-sm text-gray-400">{job.description}</div>
                        </td>
                        <td className="px-6 py-4">
                          <div className="text-sm text-white">{job.customer?.name}</div>
                        </td>
                        <td className="px-6 py-4">
                          <div className="text-sm text-white">${job.price}</div>
                        </td>
                        <td className="px-6 py-4">
                          <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full
                            ${job.status === 'Completed' ? 'bg-green-100 text-green-800' : 
                              job.status === 'In Progress' ? 'bg-yellow-100 text-yellow-800' : 
                              job.status === 'Canceled' ? 'bg-red-100 text-red-800' : 
                              job.status === 'Scheduled' ? 'bg-blue-100 text-blue-800' :
                              'bg-gray-100 text-gray-800'}`}>
                            {job.status}
                          </span>
                        </td>
                        <td className="px-6 py-4">
                          <div className="text-sm text-white">{job.scheduledDay || 'Not Scheduled'}</div>
                        </td>
                        <td className="px-6 py-4">
                          <div className="text-sm text-white">{job.location?.address}</div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            {/* Recurring Jobs Table */}
            <div className="bg-gray-800/50 rounded-xl overflow-hidden border border-gray-700">
              <h2 className="text-xl font-semibold text-white p-6">Recurring Jobs</h2>
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-700">
                  <thead className="bg-gray-900/50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Job</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Customer</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Price</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Status</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Recurrence</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Scheduled Day</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Location</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-700">
                    {jobs.recurring.map((job) => (
                      <tr key={job._id} className="hover:bg-gray-700/50 transition-colors">
                        <td className="px-6 py-4">
                          <div className="text-sm font-medium text-white">{job.service}</div>
                          <div className="text-sm text-gray-400">{job.description}</div>
                        </td>
                        <td className="px-6 py-4">
                          <div className="text-sm text-white">{job.customer?.name}</div>
                        </td>
                        <td className="px-6 py-4">
                          <div className="text-sm text-white">${job.price}</div>
                        </td>
                        <td className="px-6 py-4">
                          <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full
                            ${job.status === 'Completed' ? 'bg-green-100 text-green-800' : 
                              job.status === 'In Progress' ? 'bg-yellow-100 text-yellow-800' : 
                              job.status === 'Canceled' ? 'bg-red-100 text-red-800' : 
                              job.status === 'Scheduled' ? 'bg-blue-100 text-blue-800' :
                              'bg-gray-100 text-gray-800'}`}>
                            {job.status}
                          </span>
                        </td>
                        <td className="px-6 py-4">
                          <div className="text-sm text-white">{job.recurrencePattern}</div>
                        </td>
                        <td className="px-6 py-4">
                          <div className="text-sm text-white">{job.scheduledDay || 'Not Scheduled'}</div>
                        </td>
                        <td className="px-6 py-4">
                          <div className="text-sm text-white">{job.location?.address}</div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default Jobs;
