// src/components/QuotePDF.js
import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    padding: 50,
    backgroundColor: '#ffffff'
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 40
  },
  companyInfo: {
    fontSize: 10
  },
  companyName: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 5
  },
  recipientSection: {
    marginBottom: 30
  },
  recipientTitle: {
    fontSize: 12,
    marginBottom: 10
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 5,
    textAlign: 'right'
  },
  estimateNumber: {
    fontSize: 10,
    textAlign: 'right',
    marginBottom: 20
  },
  dates: {
    fontSize: 10,
    textAlign: 'right',
    marginBottom: 5
  },
  table: {
    marginTop: 20
  },
  tableHeader: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    paddingBottom: 5,
    marginBottom: 5
  },
  tableRow: {
    flexDirection: 'row',
    paddingVertical: 5,
    backgroundColor: '#ffffff'
  },
  serviceCol: {
    flex: 2,
    paddingRight: 8,
    fontSize: 10
  },
  descriptionCol: {
    flex: 4,
    paddingRight: 8,
    fontSize: 10
  },
  qtyCol: {
    flex: 1,
    paddingRight: 8,
    fontSize: 10,
    textAlign: 'center'
  },
  unitCol: {
    flex: 1,
    paddingRight: 8,
    fontSize: 10,
    textAlign: 'right'
  },
  totalCol: {
    flex: 1,
    fontSize: 10,
    textAlign: 'right'
  },
  totalsSection: {
    marginTop: 20,
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  totalsTable: {
    width: '30%'
  },
  totalsRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 3
  },
  totalsLabel: {
    fontSize: 10
  },
  totalsValue: {
    fontSize: 10
  },
  notes: {
    marginTop: 30,
    fontSize: 10
  },
  notesTitle: {
    fontWeight: 'bold',
    marginBottom: 5
  }
});

const QuotePDF = ({ quote, customerInfo, businessInfo }) => {
  const today = new Date().toLocaleDateString();
  const dueDate = new Date(quote.expirationDate).toLocaleDateString();

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View>
            <Text style={styles.companyName}>{businessInfo.name}</Text>
            <Text style={styles.companyInfo}>{businessInfo.website}</Text>
            <Text style={styles.companyInfo}>{businessInfo.phone}</Text>
            <Text style={styles.companyInfo}>{businessInfo.email}</Text>
            <Text style={styles.companyInfo}>{businessInfo.address}</Text>
          </View>
          <View>
            <Text style={styles.title}>Estimate</Text>
            <Text style={styles.estimateNumber}>#{quote._id}</Text>
            <Text style={styles.dates}>Estimate Sent: {today}</Text>
            <Text style={styles.dates}>Estimate Due: {dueDate}</Text>
          </View>
        </View>

        <View style={styles.recipientSection}>
          <Text style={styles.recipientTitle}>Recipient</Text>
          <Text style={styles.companyInfo}>{customerInfo.name}</Text>
          <Text style={styles.companyInfo}>{customerInfo.address.street}</Text>
          <Text style={styles.companyInfo}>{customerInfo.address.city}, {customerInfo.address.state} {customerInfo.address.zipCode}</Text>
        </View>

        <View style={styles.table}>
          <View style={styles.tableHeader}>
            <Text style={styles.serviceCol}>Service/Product</Text>
            <Text style={styles.descriptionCol}>Description</Text>
            <Text style={styles.qtyCol}>Qty</Text>
            <Text style={styles.unitCol}>Unit Cost</Text>
            <Text style={styles.totalCol}>Total</Text>
          </View>

          {quote.lineItems.map((item, index) => (
            <View key={index} style={styles.tableRow}>
              <Text style={styles.serviceCol}>{item.service}</Text>
              <Text style={styles.descriptionCol}>{item.description}</Text>
              <Text style={styles.qtyCol}>1</Text>
              <Text style={styles.unitCol}>${item.price.toFixed(2)}</Text>
              <Text style={styles.totalCol}>${item.price.toFixed(2)}</Text>
            </View>
          ))}
        </View>

        <View style={styles.totalsSection}>
          <View style={styles.totalsTable}>
            <View style={styles.totalsRow}>
              <Text style={styles.totalsLabel}>Subtotal</Text>
              <Text style={styles.totalsValue}>${quote.totalPrice.toFixed(2)}</Text>
            </View>
            <View style={styles.totalsRow}>
              <Text style={styles.totalsLabel}>Tax Rate</Text>
              <Text style={styles.totalsValue}>5.00%</Text>
            </View>
            <View style={styles.totalsRow}>
              <Text style={styles.totalsLabel}>Estimate Total</Text>
              <Text style={styles.totalsValue}>${(quote.totalPrice * 1.05).toFixed(2)}</Text>
            </View>
          </View>
        </View>

        <View style={styles.notes}>
          <Text style={styles.notesTitle}>Notes:</Text>
          <Text>{quote.terms}</Text>
        </View>
      </Page>
    </Document>
  );
};

export default QuotePDF;
