import React, { useState, useEffect } from 'react';
import axiosInstance from '../utils/axiosInstance'; // Use axiosInstance
import Header from '../components/Header';
import Alert from '../components/Alert';
import ConfirmDialog from '../components/ConfirmDialog';
import { 
  PencilIcon, 
  TrashIcon, 
  FunnelIcon, 
  CheckIcon,
  ClockIcon,
  CalendarIcon,
  UserIcon,
  CurrencyDollarIcon
} from '@heroicons/react/24/outline';

const ManageJobs = () => {
  const [jobs, setJobs] = useState({ recurring: [], oneTime: [] });
  const [filteredJobs, setFilteredJobs] = useState({ recurring: [], oneTime: [] });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [editingJob, setEditingJob] = useState(null);
  const [filter, setFilter] = useState('All');
  const [selectedJobs, setSelectedJobs] = useState({ oneTime: [], recurring: [] });
  const [currentPage, setCurrentPage] = useState({ oneTime: 1, recurring: 1 });
  const [pageSize, setPageSize] = useState(10);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    message: '',
    onConfirm: null
  });

  useEffect(() => {
    fetchJobs();
  }, []);

  useEffect(() => {
    filterJobs();
  }, [filter, jobs, pageSize, currentPage]);

  const fetchJobs = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get('/jobs', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      const recurringJobs = response.data.filter(job => job.isRecurring);
      const oneTimeJobs = response.data.filter(job => !job.isRecurring);
      setJobs({ recurring: recurringJobs, oneTime: oneTimeJobs });
      filterJobs();
    } catch (error) {
      console.error('Error fetching jobs:', error);
      setError('Failed to fetch jobs. Please try again.');
    }
    setLoading(false);
  };

  const paginate = (jobsList, type) => {
    const startIndex = (currentPage[type] - 1) * pageSize;
    return jobsList.slice(startIndex, startIndex + pageSize);
  };

  const filterJobs = () => {
    const filterByStatus = (jobsList) => {
      if (filter === 'All') return jobsList;
      return jobsList.filter(job => job.status === filter || job.recurringStatus === filter);
    };

    setFilteredJobs({
      recurring: paginate(filterByStatus(jobs.recurring), 'recurring'),
      oneTime: paginate(filterByStatus(jobs.oneTime), 'oneTime')
    });
  };

  const handleEditActualDuration = (job) => {
    setConfirmDialog({
      isOpen: true,
      title: 'Edit Actual Duration',
      message: (
        <div>
          <p>Enter actual duration in minutes:</p>
          <input
            type="number"
            defaultValue={job.actualDuration || ''}
            min="0"
            className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50 bg-gray-700 text-white"
            id="actualDurationInput"
          />
        </div>
      ),
      onConfirm: () => {
        const inputElement = document.getElementById('actualDurationInput');
        const newDuration = parseInt(inputElement.value, 10);
        if (!isNaN(newDuration) && newDuration >= 0) {
          axiosInstance.put(`/jobs/${job._id}`, 
            { actualDuration: newDuration },
            { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
          )
          .then(() => {
            fetchJobs();
            setConfirmDialog({ isOpen: false, title: '', message: '', onConfirm: null });
          })
          .catch((error) => {
            console.error('Error updating actual duration:', error);
            setError('Failed to update actual duration. Please try again.');
            setConfirmDialog({ isOpen: false, title: '', message: '', onConfirm: null });
          });
        } else {
          setError('Please enter a valid number for the actual duration.');
          setConfirmDialog({ isOpen: false, title: '', message: '', onConfirm: null });
        }
      }
    });
  };

  const handleDeleteJobs = (jobType) => {
    const jobsToDelete = selectedJobs[jobType];
    if (jobsToDelete.length === 0) return;
  
    setConfirmDialog({
      isOpen: true,
      title: 'Delete Jobs',
      message: `Are you sure you want to delete ${jobsToDelete.length} job(s)?`,
      onConfirm: async () => {
        try {
          await Promise.all(jobsToDelete.map(jobId => 
            axiosInstance.delete(`/jobs/${jobId}`, {
              headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            })
          ));
          fetchJobs();
          setSelectedJobs({ ...selectedJobs, [jobType]: [] });
        } catch (error) {
          console.error('Error deleting jobs:', error);
          setError('Failed to delete jobs. Please try again.');
        }
      }
    });
  };

  const handleEditJob = (job) => {
    setEditingJob({
      ...job,
      scheduledDay: job.scheduledDay || '',
    });
  };

  const handleCompleteJob = (jobId, isRecurring) => {
    const job = jobs.recurring.find(j => j._id === jobId) || jobs.oneTime.find(j => j._id === jobId);
    
    if (isRecurring || job.status !== 'Completed') {
      setConfirmDialog({
        isOpen: true,
        title: 'Complete Job',
        message: 'Are you sure you want to mark this job as completed?',
        onConfirm: async () => {
          try {
            const response = await axiosInstance.post(`/jobs/${jobId}/complete`, 
              {}, 
              { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
            );
            
            // Update the local state with the updated job
            const updatedJob = response.data;
            setJobs(prevJobs => ({
              recurring: prevJobs.recurring.map(j => j._id === jobId ? updatedJob : j),
              oneTime: prevJobs.oneTime.map(j => j._id === jobId ? updatedJob : j)
            }));
  
            // Optionally, you can show a success message
            setError(''); // Clear any existing errors
            // You might want to add a success state and message here
          } catch (error) {
            console.error('Error completing job:', error);
            setError('Failed to complete job. Please try again.');
          }
        }
      });
    } else if (job.status === 'Completed' && !job.isRecurring) {
      setError('This one-time job has already been completed.');
    }
  };

  const handleUpdateJob = async () => {
    try {
      await axiosInstance.put(`/jobs/${editingJob._id}`, editingJob, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      fetchJobs();
      setEditingJob(null);
    } catch (error) {
      console.error('Error updating job:', error);
      setError('Failed to update job. Please try again.');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
  
    // Handle "Not scheduled" case for scheduledDay
    setEditingJob(prevState => ({
      ...prevState,
      [name]: value === '' ? null : value
    }));
  };

  const handleBulkComplete = (jobType) => {
    const jobsToComplete = selectedJobs[jobType];
    if (jobsToComplete.length === 0) return;
  
    const jobsData = jobType === 'recurring' ? jobs.recurring : jobs.oneTime;
    const invalidJobs = jobsData
      .filter(job => jobsToComplete.includes(job._id) && !job.isRecurring && job.status === 'Completed')
      .map(job => job.title);
  
    if (invalidJobs.length > 0) {
      setError(`The following one-time jobs are already completed and cannot be completed again: ${invalidJobs.join(', ')}`);
      return;
    }
  
    setConfirmDialog({
      isOpen: true,
      title: 'Complete Jobs',
      message: `Are you sure you want to mark ${jobsToComplete.length} job(s) as completed?`,
      onConfirm: async () => {
        try {
          await Promise.all(jobsToComplete.map(jobId => 
            axiosInstance.post(`/jobs/${jobId}/complete`, 
              {}, 
              { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
            )
          ));
          fetchJobs();
          setSelectedJobs({ ...selectedJobs, [jobType]: [] });
        } catch (error) {
          console.error('Error completing jobs:', error);
          setError('Failed to complete jobs. Please try again.');
        }
      }
    });
  };

  const handleStatusChange = async (jobId, newStatus, isRecurring) => {
    try {
      const updatedStatus = isRecurring ? { recurringStatus: newStatus } : { status: newStatus };
      await axiosInstance.put(`/jobs/${jobId}`, 
        updatedStatus,
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      fetchJobs();
    } catch (error) {
      console.error('Error updating job status:', error);
      setError('Failed to update job status. Please try again.');
    }
  };

  const handleSelectJob = (jobId, jobType) => {
    setSelectedJobs(prev => ({
      ...prev,
      [jobType]: prev[jobType].includes(jobId)
        ? prev[jobType].filter(id => id !== jobId)
        : [...prev[jobType], jobId]
    }));
  };

  const handleSelectAll = (jobType) => {
    const allJobIds = filteredJobs[jobType].map(job => job._id);
    setSelectedJobs(prev => ({
      ...prev,
      [jobType]: prev[jobType].length === allJobIds.length ? [] : allJobIds
    }));
  };

  const handleBulkAction = async (action, jobType) => {
    if (selectedJobs[jobType].length === 0) return;

    const actionText = action === 'delete' ? 'delete' : 'mark as completed';
    setConfirmDialog({
      isOpen: true,
      title: `Confirm ${action}`,
      message: `Are you sure you want to ${actionText} ${selectedJobs[jobType].length} selected jobs?`,
      onConfirm: async () => {
        try {
          if (action === 'delete') {
            await Promise.all(
              selectedJobs[jobType].map(jobId =>
                axiosInstance.delete(`/jobs/${jobId}`)
              )
            );
          } else if (action === 'complete') {
            await Promise.all(
              selectedJobs[jobType].map(jobId =>
                axiosInstance.put(`/jobs/${jobId}`, { status: 'Completed' })
              )
            );
          }
          fetchJobs();
          setSelectedJobs(prev => ({ ...prev, [jobType]: [] }));
        } catch (error) {
          setError(`Failed to ${actionText} jobs. Please try again.`);
        }
      }
    });
  };

  const handlePageSizeChange = (e, type) => {
    const newSize = parseInt(e.target.value, 10);
    setPageSize(newSize);
    setCurrentPage(prev => ({ ...prev, [type]: 1 }));
  };

  const handlePageChange = (direction, jobType) => {
    setCurrentPage(prev => ({
      ...prev,
      [jobType]: direction === 'next' ? prev[jobType] + 1 : prev[jobType] - 1
    }));
  };

  const formatDay = (isoDate) => {
    if (!isoDate) return 'Not set';
    const date = new Date(isoDate);
    return date.toLocaleDateString('en-US', { weekday: 'long' });
  };

  const getPageData = (jobs, type) => {
    const startIndex = (currentPage[type] - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return jobs.slice(startIndex, endIndex);
  };

  const getTotalPages = (jobs) => {
    return Math.ceil(jobs.length / pageSize);
  };

  const Pagination = ({ totalItems, currentPage, type }) => {
    const totalPages = Math.ceil(totalItems / pageSize);
    
    if (totalPages <= 1) return null;

    return (
      <div className="flex items-center justify-between px-6 py-3 bg-gray-900/50">
        <div className="flex-1 flex justify-between sm:hidden">
          <button
            onClick={() => setCurrentPage(prev => ({ ...prev, [type]: prev[type] - 1 }))}
            disabled={currentPage === 1}
            className="relative inline-flex items-center px-4 py-2 text-sm font-medium rounded-md text-gray-300 bg-gray-800 hover:bg-gray-700 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Previous
          </button>
          <button
            onClick={() => setCurrentPage(prev => ({ ...prev, [type]: prev[type] + 1 }))}
            disabled={currentPage === totalPages}
            className="relative inline-flex items-center px-4 py-2 text-sm font-medium rounded-md text-gray-300 bg-gray-800 hover:bg-gray-700 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Next
          </button>
        </div>
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <div>
            <p className="text-sm text-gray-400">
              Showing{' '}
              <span className="font-medium">{((currentPage - 1) * pageSize) + 1}</span>
              {' '}-{' '}
              <span className="font-medium">
                {Math.min(currentPage * pageSize, totalItems)}
              </span>
              {' '}of{' '}
              <span className="font-medium">{totalItems}</span>
              {' '}results
            </p>
          </div>
          <div>
            <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
              <button
                onClick={() => setCurrentPage(prev => ({ ...prev, [type]: 1 }))}
                disabled={currentPage === 1}
                className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-700 bg-gray-800 text-sm font-medium text-gray-300 hover:bg-gray-700"
              >
                <span className="sr-only">First</span>
                ⟪
              </button>
              <button
                onClick={() => setCurrentPage(prev => ({ ...prev, [type]: prev[type] - 1 }))}
                disabled={currentPage === 1}
                className="relative inline-flex items-center px-2 py-2 border border-gray-700 bg-gray-800 text-sm font-medium text-gray-300 hover:bg-gray-700"
              >
                <span className="sr-only">Previous</span>
                ←
              </button>
              {[...Array(totalPages)].map((_, idx) => (
                <button
                  key={idx}
                  onClick={() => setCurrentPage(prev => ({ ...prev, [type]: idx + 1 }))}
                  className={`relative inline-flex items-center px-4 py-2 border border-gray-700 text-sm font-medium
                    ${currentPage === idx + 1 
                      ? 'z-10 bg-blue-600 text-white' 
                      : 'bg-gray-800 text-gray-300 hover:bg-gray-700'}`}
                >
                  {idx + 1}
                </button>
              ))}
              <button
                onClick={() => setCurrentPage(prev => ({ ...prev, [type]: prev[type] + 1 }))}
                disabled={currentPage === totalPages}
                className="relative inline-flex items-center px-2 py-2 border border-gray-700 bg-gray-800 text-sm font-medium text-gray-300 hover:bg-gray-700"
              >
                <span className="sr-only">Next</span>
                →
              </button>
              <button
                onClick={() => setCurrentPage(prev => ({ ...prev, [type]: totalPages }))}
                disabled={currentPage === totalPages}
                className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-700 bg-gray-800 text-sm font-medium text-gray-300 hover:bg-gray-700"
              >
                <span className="sr-only">Last</span>
                ⟫
              </button>
            </nav>
          </div>
        </div>
      </div>
    );
  };

  const renderJobTable = (jobs, jobType) => {
    if (!jobs || !Array.isArray(jobs)) {
      return <div>No jobs to display</div>;
    }
  
    return (
      <div className="overflow-x-auto mt-4">
        <h2 className="text-xl font-bold mb-2 text-white">{jobType === 'recurring' ? 'Recurring Jobs' : 'One-Time Jobs'}</h2>
        <div className="mb-2">
          {selectedJobs[jobType] && selectedJobs[jobType].length > 0 && (
            <>
              <button
                onClick={() => handleBulkAction('delete', jobType)}
                className="bg-red-500 text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 mr-2"
              >
                Delete Selected
              </button>
              <button
                onClick={() => handleBulkAction('complete', jobType)}
                className={`bg-green-500 text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 mr-2 ${
                  jobType === 'oneTime' && selectedJobs[jobType].some(id => 
                    jobs.find(job => job._id === id && job.status === 'Completed')
                  ) ? 'opacity-50 cursor-not-allowed' : ''
                }`}
                disabled={jobType === 'oneTime' && selectedJobs[jobType].some(id => 
                  jobs.find(job => job._id === id && job.status === 'Completed')
                )}
              >
                Complete Selected
              </button>
            </>
          )}
          {selectedJobs[jobType] && selectedJobs[jobType].length === 1 && (
            <button
              onClick={() => handleEditJob(jobs.find(job => job._id === selectedJobs[jobType][0]))}
              className="bg-blue-500 text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Edit Selected
            </button>
          )}
        </div>
        <table className="min-w-full divide-y divide-gray-700">
          <thead className="bg-gray-700">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                <input
                  type="checkbox"
                  onChange={(e) => handleSelectAll(jobType)}
                  checked={selectedJobs[jobType] && selectedJobs[jobType].length === jobs.length && jobs.length > 0}
                />
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Job</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Customer</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Price</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Status</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Route Day</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Last Service Date</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Completion Count</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Total Revenue</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Estimated Duration</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Actual Minutes</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-gray-900 divide-y divide-gray-700">
            {jobs.map((job) => (
              <tr key={job._id}>
                <td className="px-6 py-4 whitespace-nowrap">
                  <input
                    type="checkbox"
                    onChange={() => handleSelectJob(job._id, jobType)}
                    checked={selectedJobs[jobType] && selectedJobs[jobType].includes(job._id)}
                  />
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-white">{job.title}</div>
                  <div className="text-sm text-gray-400">{job.description}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-white">{job.customer?.name || 'No customer assigned'}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-white">${job.price}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {jobType === 'recurring' ? (
                    <select
                      value={job.recurringStatus}
                      onChange={(e) => handleStatusChange(job._id, e.target.value, true)}
                      className="text-sm text-white border rounded p-1 bg-primary"
                    >
                      <option value="Active" className="bg-primary text-white">Active</option>
                      <option value="Paused" className="bg-primary text-white">Paused</option>
                      <option value="Canceled" className="bg-primary text-white">Canceled</option>
                    </select>
                  ) : (
                    <select
                      value={job.status}
                      onChange={(e) => handleStatusChange(job._id, e.target.value, false)}
                      className="text-sm text-white border rounded p-1 bg-primary"
                    >
                      <option value="Pending" className="bg-primary text-white">Pending</option>
                      <option value="Scheduled" className="bg-primary text-white">Scheduled</option>
                      <option value="In Progress" className="bg-primary text-white">In Progress</option>
                      <option value="Completed" className="bg-primary text-white">Completed</option>
                      <option value="Canceled" className="bg-primary text-white">Canceled</option>
                    </select>
                  )}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                  {formatDay(job.scheduledDay)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-white">{job.lastServiceDate ? new Date(job.lastServiceDate).toLocaleDateString() : 'N/A'}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-white">{job.completionCount}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-white">${job.price * job.completionCount}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-white">
                    {job.estimatedDuration ? `${job.estimatedDuration} min` : 'Not set'}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-white">
                    {job.actualDuration !== undefined ? (
                      <>
                        {job.actualDuration} min
                        <button
                          onClick={() => handleEditActualDuration(job)}
                          className="text-blue-500 ml-2"
                        >
                          <PencilIcon className="h-5 w-5" />
                        </button>
                      </>
                    ) : (
                      <button
                        onClick={() => handleEditActualDuration(job)}
                        className="text-blue-500"
                      >
                        Set <PencilIcon className="h-5 w-5 inline-block" />
                      </button>
                    )}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                  <div className="flex items-center space-x-2">
                    <button
                      onClick={() => handleCompleteJob(job._id, jobType === 'recurring')}
                      className={`text-green-600 hover:text-green-900 ${(jobType === 'oneTime' && job.status === 'Completed') ? 'opacity-50 cursor-not-allowed' : ''}`}
                      disabled={jobType === 'oneTime' && job.status === 'Completed'}
                    >
                      <CheckIcon className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => handleEditJob(job)}
                      className="text-blue-600 hover:text-blue-900"
                    >
                      <PencilIcon className="h-5 w-5" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination 
          totalItems={jobs.length}
          currentPage={currentPage[jobType]}
          type={jobType}
        />
      </div>
    );
  };

  // Add revenue calculation
  const calculateTotalRevenue = (jobs) => {
    return jobs.reduce((total, job) => {
      if (job.status === 'Completed') {
        return total + (job.price * (job.completionCount || 1));
      }
      return total;
    }, 0);
  };

  const PageSizeSelector = ({ type }) => (
    <select
      value={pageSize}
      onChange={(e) => handlePageSizeChange(e, type)}
      className="bg-gray-700 text-white border border-gray-600 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
    >
      <option value={10}>10 per page</option>
      <option value={20}>20 per page</option>
      <option value={50}>50 per page</option>
      <option value={100}>100 per page</option>
    </select>
  );

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900">
      <Header />
      <main className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
        {/* Revenue Summary */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          <div className="bg-gray-800 rounded-xl p-6 border border-gray-700">
            <h3 className="text-lg font-medium text-gray-300">One-Time Jobs Revenue</h3>
            <p className="text-3xl font-bold text-white mt-2">
              ${calculateTotalRevenue(jobs.oneTime).toFixed(2)}
            </p>
          </div>
          <div className="bg-gray-800 rounded-xl p-6 border border-gray-700">
            <h3 className="text-lg font-medium text-gray-300">Recurring Jobs Revenue</h3>
            <p className="text-3xl font-bold text-white mt-2">
              ${calculateTotalRevenue(jobs.recurring).toFixed(2)}
            </p>
          </div>
        </div>

        {/* Page Header */}
        <div className="flex flex-col md:flex-row md:items-center justify-between mb-8">
          <div>
            <h1 className="text-3xl font-bold text-white">Manage Jobs</h1>
            <p className="mt-1 text-gray-400">View and manage all job records</p>
          </div>
          
          {/* Filter Controls */}
          <div className="mt-4 md:mt-0 flex flex-wrap gap-4">
            <PageSizeSelector type="oneTime" />
            <select
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              className="bg-gray-700 text-white border border-gray-600 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            >
              <option value="All">All Status</option>
              <option value="Pending">Pending</option>
              <option value="Scheduled">Scheduled</option>
              <option value="In Progress">In Progress</option>
              <option value="Completed">Completed</option>
              <option value="Canceled">Canceled</option>
            </select>
          </div>
        </div>

        {/* Alert Messages */}
        {error && <Alert type="error" message={error} />}

        {/* Job Tables */}
        <div className="space-y-8">
          {/* One-Time Jobs */}
          <div className="bg-gray-800 rounded-xl shadow-xl overflow-hidden border border-gray-700">
            <div className="p-6 border-b border-gray-700">
              <div className="flex justify-between items-center">
                <h2 className="text-xl font-semibold text-white flex items-center">
                  <CalendarIcon className="h-6 w-6 mr-2 text-blue-500" />
                  One-Time Jobs
                </h2>
                {selectedJobs.oneTime.length > 0 && (
                  <div className="flex space-x-4">
                    <button
                      onClick={() => handleBulkAction('complete', 'oneTime')}
                      className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors flex items-center space-x-2"
                    >
                      <CheckIcon className="h-5 w-5" />
                      <span>Complete Selected</span>
                    </button>
                    <button
                      onClick={() => handleBulkAction('delete', 'oneTime')}
                      className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors flex items-center space-x-2"
                    >
                      <TrashIcon className="h-5 w-5" />
                      <span>Delete Selected</span>
                    </button>
                  </div>
                )}
              </div>
            </div>
            
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-700">
                <thead className="bg-gray-900/50">
                  <tr>
                    <th className="px-6 py-3 text-left">
                      <input
                        type="checkbox"
                        checked={selectedJobs.oneTime.length === filteredJobs.oneTime.length}
                        onChange={() => handleSelectAll('oneTime')}
                        className="rounded border-gray-600 text-blue-600 focus:ring-blue-500"
                      />
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Service</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Customer</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Price</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Status</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Schedule</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Duration</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Actions</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-700">
                  {getPageData(filteredJobs.oneTime, 'oneTime').map((job) => (
                    <tr key={job._id} className="hover:bg-gray-700/50 transition-colors">
                      <td className="px-6 py-4">
                        <input
                          type="checkbox"
                          checked={selectedJobs.oneTime.includes(job._id)}
                          onChange={() => handleSelectJob(job._id, 'oneTime')}
                          className="rounded border-gray-600 text-blue-600 focus:ring-blue-500"
                        />
                      </td>
                      <td className="px-6 py-4">
                        <div className="text-sm font-medium text-white">{job.service}</div>
                        <div className="text-sm text-gray-400">{job.description}</div>
                      </td>
                      <td className="px-6 py-4">
                        <div className="text-sm text-white">{job.customer?.name}</div>
                      </td>
                      <td className="px-6 py-4">
                        <div className="text-sm text-white">${job.price}</div>
                      </td>
                      <td className="px-6 py-4">
                        <span className={`px-3 py-1 inline-flex text-xs leading-5 font-semibold rounded-full
                          ${job.status === 'Completed' ? 'bg-green-100 text-green-800' : 
                            job.status === 'In Progress' ? 'bg-yellow-100 text-yellow-800' : 
                            job.status === 'Canceled' ? 'bg-red-100 text-red-800' : 
                            job.status === 'Scheduled' ? 'bg-blue-100 text-blue-800' :
                            'bg-gray-100 text-gray-800'}`}>
                          {job.status}
                        </span>
                      </td>
                      <td className="px-6 py-4">
                        <div className="text-sm text-white">{job.scheduledDay || 'Not Scheduled'}</div>
                      </td>
                      <td className="px-6 py-4">
                        <div className="text-sm text-white flex items-center">
                          <ClockIcon className="h-4 w-4 mr-1 text-gray-400" />
                          {job.estimatedDuration} min
                        </div>
                      </td>
                      <td className="px-6 py-4 text-sm font-medium">
                        <div className="flex space-x-3">
                          <button
                            onClick={() => handleEditJob(job)}
                            className="text-blue-400 hover:text-blue-300 transition-colors"
                          >
                            <PencilIcon className="h-5 w-5" />
                          </button>
                          <button
                            onClick={() => handleDeleteJobs('oneTime')}
                            className="text-red-400 hover:text-red-300 transition-colors"
                          >
                            <TrashIcon className="h-5 w-5" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {/* Recurring Jobs */}
          <div className="bg-gray-800 rounded-xl shadow-xl overflow-hidden border border-gray-700">
            <div className="p-6 border-b border-gray-700">
              <div className="flex justify-between items-center">
                <h2 className="text-xl font-semibold text-white flex items-center">
                  <ClockIcon className="h-6 w-6 mr-2 text-green-500" />
                  Recurring Jobs
                </h2>
                {selectedJobs.recurring.length > 0 && (
                  <div className="flex space-x-4">
                    <button
                      onClick={() => handleBulkAction('complete', 'recurring')}
                      className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors flex items-center space-x-2"
                    >
                      <CheckIcon className="h-5 w-5" />
                      <span>Complete Selected</span>
                    </button>
                    <button
                      onClick={() => handleBulkAction('delete', 'recurring')}
                      className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors flex items-center space-x-2"
                    >
                      <TrashIcon className="h-5 w-5" />
                      <span>Delete Selected</span>
                    </button>
                  </div>
                )}
              </div>
            </div>
            
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-700">
                <thead className="bg-gray-900/50">
                  <tr>
                    <th className="px-6 py-3 text-left">
                      <input
                        type="checkbox"
                        checked={selectedJobs.recurring.length === filteredJobs.recurring.length}
                        onChange={() => handleSelectAll('recurring')}
                        className="rounded border-gray-600 text-blue-600 focus:ring-blue-500"
                      />
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Service</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Customer</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Price</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Status</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Recurring Status</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Frequency</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Completions</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Next Schedule</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Duration</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Actions</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-700">
                  {getPageData(filteredJobs.recurring, 'recurring').map((job) => (
                    <tr key={job._id} className="hover:bg-gray-700/50 transition-colors">
                      <td className="px-6 py-4">
                        <input
                          type="checkbox"
                          checked={selectedJobs.recurring.includes(job._id)}
                          onChange={() => handleSelectJob(job._id, 'recurring')}
                          className="rounded border-gray-600 text-blue-600 focus:ring-blue-500"
                        />
                      </td>
                      <td className="px-6 py-4">
                        <div className="text-sm font-medium text-white">{job.service}</div>
                        <div className="text-sm text-gray-400">{job.description}</div>
                      </td>
                      <td className="px-6 py-4">
                        <div className="text-sm text-white">{job.customer?.name}</div>
                      </td>
                      <td className="px-6 py-4">
                        <div className="text-sm text-white">${job.price}</div>
                      </td>
                      <td className="px-6 py-4">
                        <span className={`px-3 py-1 inline-flex text-xs leading-5 font-semibold rounded-full
                          ${job.status === 'Completed' ? 'bg-green-100 text-green-800' : 
                            job.status === 'In Progress' ? 'bg-yellow-100 text-yellow-800' : 
                            job.status === 'Canceled' ? 'bg-red-100 text-red-800' : 
                            job.status === 'Scheduled' ? 'bg-blue-100 text-blue-800' :
                            'bg-gray-100 text-gray-800'}`}>
                          {job.status}
                        </span>
                      </td>
                      <td className="px-6 py-4">
                        <span className={`px-3 py-1 inline-flex text-xs leading-5 font-semibold rounded-full
                          ${job.recurringStatus === 'Active' ? 'bg-green-100 text-green-800' : 
                            job.recurringStatus === 'Paused' ? 'bg-yellow-100 text-yellow-800' : 
                            'bg-red-100 text-red-800'}`}>
                          {job.recurringStatus}
                        </span>
                      </td>
                      <td className="px-6 py-4">
                        <div className="text-sm text-white capitalize">{job.recurrencePattern}</div>
                      </td>
                      <td className="px-6 py-4">
                        <div className="text-sm text-white">{job.completionCount || 0}</div>
                      </td>
                      <td className="px-6 py-4">
                        <div className="text-sm text-white">
                          {job.nextScheduledDate ? new Date(job.nextScheduledDate).toLocaleDateString() : 'Not scheduled'}
                        </div>
                      </td>
                      <td className="px-6 py-4">
                        <div className="text-sm text-white">
                          <div className="flex flex-col">
                            <span>Est: {job.estimatedDuration} min</span>
                            {job.actualDuration > 0 && (
                              <span className="text-gray-400">
                                Act: {job.actualDuration} min
                              </span>
                            )}
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 text-sm font-medium">
                        <div className="flex space-x-3">
                          <button
                            onClick={() => handleEditJob(job)}
                            className="text-blue-400 hover:text-blue-300 transition-colors"
                          >
                            <PencilIcon className="h-5 w-5" />
                          </button>
                          <button
                            onClick={() => handleDeleteJobs('recurring')}
                            className="text-red-400 hover:text-red-300 transition-colors"
                          >
                            <TrashIcon className="h-5 w-5" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* Edit Modal */}
        {editingJob && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-gray-800 rounded-xl shadow-2xl w-full max-w-2xl">
              <div className="p-6 border-b border-gray-700">
                <h3 className="text-xl font-semibold text-white">Edit Job</h3>
              </div>
              
              <div className="p-6 space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {/* Status */}
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-300">
                      Status
                    </label>
                    <select
                      name="status"
                      value={editingJob.status}
                      onChange={handleInputChange}
                      className="w-full bg-gray-700 border border-gray-600 text-white rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    >
                      <option value="Pending">Pending</option>
                      <option value="Scheduled">Scheduled</option>
                      <option value="In Progress">In Progress</option>
                      <option value="Completed">Completed</option>
                      <option value="Canceled">Canceled</option>
                    </select>
                  </div>

                  {/* Scheduled Day */}
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-300">
                      Scheduled Day
                    </label>
                    <select
                      name="scheduledDay"
                      value={editingJob.scheduledDay || ''}
                      onChange={handleInputChange}
                      className="w-full bg-gray-700 border border-gray-600 text-white rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    >
                      <option value="">Not scheduled</option>
                      <option value="Monday">Monday</option>
                      <option value="Tuesday">Tuesday</option>
                      <option value="Wednesday">Wednesday</option>
                      <option value="Thursday">Thursday</option>
                      <option value="Friday">Friday</option>
                      <option value="Saturday">Saturday</option>
                      <option value="Sunday">Sunday</option>
                    </select>
                  </div>

                  {/* Estimated Duration */}
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-300">
                      Estimated Duration (minutes)
                    </label>
                    <input
                      type="number"
                      name="estimatedDuration"
                      value={editingJob.estimatedDuration}
                      onChange={handleInputChange}
                      className="w-full bg-gray-700 border border-gray-600 text-white rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    />
                  </div>

                  {/* Price */}
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-300">
                      Price ($)
                    </label>
                    <input
                      type="number"
                      name="price"
                      value={editingJob.price}
                      onChange={handleInputChange}
                      className="w-full bg-gray-700 border border-gray-600 text-white rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    />
                  </div>
                </div>
              </div>

              <div className="px-6 py-4 bg-gray-900/50 flex justify-end space-x-4">
                <button
                  onClick={() => setEditingJob(null)}
                  className="px-4 py-2 text-gray-300 hover:text-white transition-colors"
                >
                  Cancel
                </button>
                <button
                  onClick={handleUpdateJob}
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Confirm Dialog */}
        <ConfirmDialog
          isOpen={confirmDialog.isOpen}
          title={confirmDialog.title}
          message={confirmDialog.message}
          onConfirm={() => {
            if (confirmDialog.onConfirm) {
              confirmDialog.onConfirm();
            }
            setConfirmDialog({ ...confirmDialog, isOpen: false });
          }}
          onCancel={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}
        />
      </main>
    </div>
  );
};

export default ManageJobs;
