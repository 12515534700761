// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// Public Pages
import Home from './pages/Home';
import Features from './pages/Features';
import Pricing from './pages/Pricing';
import Contact from './pages/Contact';
import About from './pages/About';
import PublicProfile from './pages/PublicProfile';

// Auth Pages
import SignIn from './components/SignIn';
import Register from './components/Register';
import OAuthSuccess from './pages/OAuthSuccess';
import LoginSuccess from './components/LoginSuccess';
import PaymentSuccess from './pages/PaymentSuccess';

// Protected Pages
import Dashboard from './components/Dashboard';
import Jobs from './pages/Jobs';
import Customers from './pages/Customers';
import ManageJobs from './pages/ManageJobs';
import ManageCustomers from './pages/ManageCustomers';
import Profile from './pages/Profile';
import ManageEmployees from './pages/ManageEmployees';
import Employee from './pages/Employee';
import QuoteBuilderPage from './pages/QuoteBuilderPage';
import RouteMap from './pages/RouteMap';
import SendNotifications from './pages/SendNotifications';
import MaterialCalculator from './pages/MaterialCalculator';
import Calendar from './pages/Calendar';

function App() {
  return (
    <DndProvider backend={HTML5Backend}>
      <AuthProvider>
        <Router>
          <div className="App">
            <Routes>
              {/* Public Routes */}
              <Route path="/" element={<Home />} />
              <Route path="/features" element={<Features />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/public-profile" element={<PublicProfile />} />
              <Route path="/signin" element={<SignIn />} />
              <Route path="/register" element={<Register />} />
              <Route path="/oauth-success" element={<OAuthSuccess />} />
              <Route path="/login-success" element={<LoginSuccess />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/quote-builder" element={<QuoteBuilderPage />} />
              <Route path="/payment-success" element={<PaymentSuccess />} />

              {/* All app functionality requires subscription */}
              <Route element={<ProtectedRoute requireSubscription={true} />}>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/calendar" element={<Calendar />} />
                <Route path="/route-map" element={<RouteMap />} />
                <Route path="/jobs" element={<Jobs />} />
                <Route path="/manage-jobs" element={<ManageJobs />} />
                <Route path="/customers" element={<Customers />} />
                <Route path="/manage-customers" element={<ManageCustomers />} />
                <Route path="/manage-employees" element={<ManageEmployees />} />
                <Route path="/add-employee" element={<Employee />} />
                <Route path="/send-notifications" element={<SendNotifications />} />
                <Route path="/material-calculator" element={<MaterialCalculator />} />
              </Route>
            </Routes>
          </div>
        </Router>
      </AuthProvider>
    </DndProvider>
  );
}

export default App;
