import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Select } from 'antd';

const RecurringJobModal = ({ isOpen, onClose, onSelect, existingRoute, routes, job }) => {
  const handleSelect = (choice, routeId) => {
    if (routeId) {
      onSelect('selectRoute', routeId);
    } else {
      onSelect(choice);
    }
    onClose();
  };

  // If it's a one-time job and there are routes, only show route selection
  if (!job?.isRecurring && routes?.length > 0) {
    return (
      <Modal show={isOpen} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Select Route</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Please select which route to add this job to:</p>
          <Select
            className="w-full mb-4"
            placeholder="Select a route"
            onChange={(value) => handleSelect('selectRoute', value)}
          >
            {routes.map(route => (
              <Select.Option key={route._id} value={route._id}>
                {route.name || `Route ${route._id.substring(0, 8)}`} ({route.jobs.length} jobs)
              </Select.Option>
            ))}
          </Select>
        </Modal.Body>
      </Modal>
    );
  }

  // For recurring jobs, show all options
  if (job?.isRecurring) {
    return (
      <Modal show={isOpen} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Schedule Recurring Job</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="space-y-4">
            <Button
              variant="primary"
              className="w-full"
              onClick={() => handleSelect('single')}
            >
              Schedule for this day only
            </Button>
            <Button
              variant="primary"
              className="w-full"
              onClick={() => handleSelect('newSeries')}
            >
              Create new recurring series
            </Button>
            {existingRoute && (
              <>
                <Button
                  variant="info"
                  className="w-full"
                  onClick={() => handleSelect('attachFuture')}
                >
                  Add to existing series (from this date forward)
                </Button>
                <Button
                  variant="success"
                  className="w-full"
                  onClick={() => handleSelect('attachAll')}
                >
                  Add to existing series (all dates)
                </Button>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  return null;
};

export default RecurringJobModal; 